import { css, Theme } from '@emotion/react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const headings = (theme: Theme) =>
  css`
    color: ${theme.colors.headingColor};
    line-height: 1.2;
    margin: 0;
    font-family: var(--font-hankenGrotesk);
  `

export const heading1 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 40px;

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: 52px;
  }
  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 56px;
  }
  @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
    font-size: 64px;
  }
`
export const heading2 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 36px;
  margin-bottom: ${theme.spacing.x4}px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 54px;
    margin-bottom: ${theme.spacing.x7}px;
  }
`
export const heading3 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 26px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 30px;
  }
`
export const heading4 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 21px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 21px;
  }
`
export const heading5 = (theme: Theme) => css`
  ${theme.text.headings(theme)}
  font-size: 16px;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`

export const titleLine = (theme: Theme) => css`
  border-left: 5px solid ${theme.colors.primaryBlue};
`
