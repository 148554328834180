import { css, Theme } from '@emotion/react'

const defaultLinkStyling = css`
  text-decoration: none;
  line-height: 24px;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
`

export const white = (theme: Theme) => css`
  ${defaultLinkStyling}
  color: ${theme.colors.white};
`

export const blue = (theme: Theme) => css`
  ${defaultLinkStyling}
  color: ${theme.colors.primaryBlue};
`

export const neutral3 = (theme: Theme) => css`
  ${defaultLinkStyling}
  color: ${theme.colors.neutral3};
`
