import * as colors from './colors'
import * as breakpoints from './breakpoints'
import * as spacing from './spacing'
import * as zIndex from './zIndex'
import * as text from './text'
import * as links from './links'
import fonts from './fonts'

const theme = {
  colors,
  breakpoints,
  spacing,
  zIndex,
  text,
  links,
  fonts,
}

export default theme
