// min-width
export const mobile = 0
export const mobileLarge = 640
export const tablet = 768
export const desktop = 992
export const desktopLarge = 1300

// When using a max-width breakpoint, this needs to be non-inclusive as not to
// be true at the same time as a min-width breakpoint for the same number.
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
const maxMargin = 0.02

export const mobileMax = tablet - maxMargin
export const tabletMax = desktop - maxMargin
export const desktopMax = desktopLarge - maxMargin
// desktop large has no max
