// Defaults file, for site-specific theme, go into a site folder

/* eslint-disable @typescript-eslint/no-inferrable-types */
export const primaryBlue: string = '#266BEE'
export const midBlue: string = '#8EB4F5'
export const lightBlue: string = '#EDF2FF'
export const darkBlue: string = '#2952A0'
export const primaryBlueExtraLight: string = '#d4c8e3'

export const sand1 = '#EDBE62'
export const sand2 = '#F5DEAD'

export const neutral0: string = '#fff'
export const white = neutral0
export const neutral1: string = '#dee7d9'
export const neutral2: string = '#858784'
export const neutral3: string = '#4f4f4f'
export const neutral4: string = '#000'

export const error: string = '#EC5428'
export const green: string = '#82D453'
export const green2: string = '#54BA1A'
export const greenLight: string = '#BDE9A2'

export const headingColor: string = neutral4
export const bodyTextColor: string = neutral4
export const pageBackgroundColor: string = white

export const buttonPrimaryBackground: string = primaryBlue
export const buttonPrimaryColor: string = white
export const buttonPrimaryHoverBackground: string = white
export const buttonPrimaryHoverColor: string = primaryBlue
export const buttonPrimaryDisabledBackground: string = primaryBlueExtraLight
export const buttonPrimaryDisabledColor: string = neutral1

export const buttonSecondaryBackground: string = green
export const buttonSecondaryColor: string = white
export const buttonSecondaryHoverBackground: string = white
export const buttonSecondaryHoverColor: string = green

export const buttonInvertedBackground: string = white
export const buttonInvertedColor: string = green
export const buttonInvertedHoverBackground: string = green2
export const buttonInvertedHoverColor: string = white
export const buttonInvertedDisabledBackground: string = primaryBlueExtraLight
export const buttonInvertedDisabledColor: string = neutral1
