// # Relatives

// To be used for an element to appear behind its siblings without having to
// give the siblings a z-index. Requires its container to have a z-index (can be
// 0).
export const behind = -1
export const behind2 = -2
export const behind3 = -3
export const behind4 = -4
export const behind5 = -5
// The base z-index for any components. Often best to manually on a container
// of behindSiblings or relative z-indexes so the children aren't z-index
// positioned absolutely
export const base = 0
// To place elements relative to a sibling. Do not use for absolute z-indexes.
export const relative1 = 1
export const relative2 = 2
export const relative3 = 3
export const relative4 = 4
export const relative5 = 5

// # Absolutes

export const header = 40

export const modal = 100
