import { css, Global } from '@emotion/react'

import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'

const GlobalStyles = () => {
  return (
    <>
      {/* Ensure the fonts are applied within the head to prevent the move of style elements from body->Head (Emotion) not to cause any issues */}
      <style jsx global>{`
        body {
          font-family: var(--font-roboto);
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: var(--font-roboto);
        }
      `}</style>
      <Global
        styles={(theme) => [
          css`
            html {
              scroll-padding-top: ${theme.spacing.navigationHeight.mobile}px;

              @media screen and (min-width: ${theme.breakpoints.desktop}px) {
                scroll-padding-top: ${theme.spacing.navigationHeight.desktop}px;
              }
            }
            body {
              overflow-x: auto;
              font-family: var(--font-roboto);
              font-size: 16px;
              line-height: 1.6;
              color: ${theme.colors.neutral4};
              background: ${theme.colors.pageBackgroundColor};

              @media screen and (min-width: ${theme.breakpoints.tablet}px) {
                font-size: 16px;
              }
            }
          `,
          css`
            h1,
            h2,
            h3,
            h4,
            h5 {
              ${theme.text.headings(theme)}
            }
          `,

          css`
            p {
              margin: ${theme.spacing.x2}px 0;
            }
          `,

          // When the image is an exact fit, this does nothing. When the image
          // aspect ratio mismatches, this will fit to fill without distortion.
          // Wanting distortion or empty space instead is super rare, so this is a
          // better default.
          css`
            img {
              object-fit: cover;
            }
          `,

          css`
            a {
              color: ${theme.colors.primaryBlue};
              text-decoration: none;

              :hover {
                text-decoration: underline;
              }
            }
          `,
          css`
            svg {
              height: 1.2em;
              vertical-align: text-bottom;
            }
          `,
          css`
            hr {
              border: 0;
              border-top: 1px solid ${theme.colors.neutral2};
              margin: ${theme.spacing.x2}px 0;
            }
          `,
        ]}
      />
    </>
  )
}

export default GlobalStyles
