// eslint-disable-next-line camelcase
import { Roboto, Hanken_Grotesk } from 'next/font/google'

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '300', '400', '500', '700', '900'],
  variable: '--font-roboto',
})

const hankenGrotesk = Hanken_Grotesk({
  subsets: ['latin'],
  weight: 'variable',
  variable: '--font-hankenGrotesk',
})

const fonts = {
  roboto,
  hankenGrotesk,
}

export default fonts
